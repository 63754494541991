import OuvidoriaReportsFirstSemesterTwoThousandAndTwentyOne from '../../../docs/cora_ouvidoria_1_semestre_2021.pdf'
import OuvidoriaReportsSecondSemesterTwoThousandAndTwentyOne from '../../../docs/cora_ouvidoria_2_semestre_2021.pdf'
import OuvidoriaReportsFirstSemesterTwoThousandAndTwentyTwo from '../../../docs/cora_ouvidoria_1_semestre_2022.pdf'
import OuvidoriaReportsSecondSemesterTwoThousandAndTwentyTwo from '../../../docs/cora_ouvidoria_2_semestre_2022.pdf'
import OuvidoriaReportsFirstSemesterTwoThousandAndTwentyThree from '../../../docs/cora_ouvidoria_1_semestre_2023.pdf'
import OuvidoriaReportsSecondSemesterTwoThousandAndTwentyThree from '../../../docs/cora_ouvidoria_2_semestre_2023.pdf'
import OuvidoriaReportsFirstSemesterTwoThousandAndTwentyFour from '../../../docs/cora_ouvidoria_1_semestre_2024.pdf'
import OuvidoriaReportsSecondSemesterTwoThousandAndTwentyFour from '../../../docs/cora_ouvidoria_2_semestre_2024.pdf'

export const dataReport = [
  {
    title: 'Relatório do 1º Semestre de 2021',
    downloadName: 'Relatório Ouvidoria - 1º Semestre de 2021',
    href: OuvidoriaReportsFirstSemesterTwoThousandAndTwentyOne,
  },
  {
    title: 'Relatório do 2º Semestre de 2021',
    downloadName: 'Relatório Ouvidoria - 2º Semestre de 2021',
    href: OuvidoriaReportsSecondSemesterTwoThousandAndTwentyOne,
  },
  {
    title: 'Relatório do 1º Semestre de 2022',
    downloadName: 'Relatório Ouvidoria - 1º Semestre de 2022',
    href: OuvidoriaReportsFirstSemesterTwoThousandAndTwentyTwo,
  },
  {
    title: 'Relatório do 2º Semestre de 2022',
    downloadName: 'Relatório Ouvidoria - 2º Semestre de 2022',
    href: OuvidoriaReportsSecondSemesterTwoThousandAndTwentyTwo,
  },
  {
    title: 'Relatório do 1º Semestre de 2023',
    downloadName: 'Relatório Ouvidoria - 1º Semestre de 2023',
    href: OuvidoriaReportsFirstSemesterTwoThousandAndTwentyThree,
  },
  {
    title: 'Relatório do 2º Semestre de 2023',
    downloadName: 'Relatório Ouvidoria - 2º Semestre de 2023',
    href: OuvidoriaReportsSecondSemesterTwoThousandAndTwentyThree,
  },
  {
    title: 'Relatório do 1º Semestre de 2024',
    downloadName: 'Relatório Ouvidoria - 1º Semestre de 2024',
    href: OuvidoriaReportsFirstSemesterTwoThousandAndTwentyFour,
  },
  {
    title: 'Relatório do 2º Semestre de 2024',
    downloadName: 'Relatório Ouvidoria - 2º Semestre de 2024',
    href: OuvidoriaReportsSecondSemesterTwoThousandAndTwentyFour,
  },
]
